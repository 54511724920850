<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    message: {
        type: String,
        required: true
    },
    type: {
        type: String
    },
    appender: {
        type: String,
        required: false,
    },
    appenderUrl: {
        type: String,
        required: false,
    }
});

const typeClass = computed(() => {
    switch (props.type) {
        case 'primary':
            return 'bg-primary-light text-primary';
        case 'info':
            return 'bg-info text-accent';
        default:
            return 'bg-secondary text-accent';
    }
});

const borderClass = computed(() => {
    if (props.appender && props.appenderUrl) {
        return 'rounded-l rounded-r-none';
    }

    return 'rounded';
});
</script>

<template>
    <span>
        <span class="badge border-none" :class="[typeClass, borderClass]">
            <template v-if="$slots.content">
                <slot name="content"></slot>
            </template>
            {{ props.message }}
        </span>
        <template v-if="props.appender && props.appenderUrl">
            <div class="tooltip tooltip-accent tooltip-bottom" :data-tip="$t('labels.upgrade')">
                <Link
                    :href="props.appenderUrl"
                >
                    <span class="badge border-none rounded-r rounded-l-none bg-warning text-primary-content">
                        {{ props.appender }} <i class="ms-1 far fa-plus"></i>
                    </span>
                </Link>
            </div>
        </template>
    </span>
</template>
